import { Unfurl } from "@converge-collective/common/models/Unfurl";
import { Metadata } from "@converge-collective/common/unfurlTypes";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { uniq } from "lodash";
import { useUnfurl } from "~/hooks/useUnfurl";

type UnfurlResult = Unfurl["unfurlResult"];

const isMetadata = (unfurlResult: UnfurlResult): unfurlResult is Metadata =>
  Boolean(unfurlResult) && !unfurlResult?.hasOwnProperty("error");

function RenderValidUnfurl({
  url,
  unfurlResult,
}: {
  url: string;
  unfurlResult: Metadata;
}) {
  const { open_graph } = unfurlResult;
  const title = unfurlResult.title || open_graph?.title;
  const description = unfurlResult.description || open_graph?.description;
  const img = open_graph?.images?.[0]?.url;

  const [col1, col2] = img ? [2, 10] : [0, 12];

  if (!title && !description && !img) {
    return null;
  }

  return (
    <>
      {img && (
        <Grid sx={{ height: "auto" }} size={{ xs: col1 }}>
          {img ? (
            <img
              style={{
                display: "block",
                objectFit: "cover",
                maxHeight: 120,
                maxWidth: "100%",
              }}
              src={img}
              alt={title}
            />
          ) : null}
        </Grid>
      )}
      <Grid sx={{ height: "auto" }} size={{ xs: col2 }}>
        <Paper sx={{ ml: 1, p: 1.4, height: "100%" }} variant="outlined">
          <Typography variant="body1">
            <a href={url}>{title}</a>
          </Typography>
          <Typography
            sx={{
              my: 2,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
            }}
            variant="body2"
          >
            {description}
          </Typography>
        </Paper>
      </Grid>
    </>
  );
}

function TryRenderUnfurl({ url }: { url: string }) {
  const { unfurl } = useUnfurl(url);
  // console.log("Unfurl", { unfurl });
  return (
    <>
      {isMetadata(unfurl?.unfurlResult) ? (
        <RenderValidUnfurl url={url} unfurlResult={unfurl?.unfurlResult} />
      ) : null}
    </>
  );
}

export function RenderUnfurls({ urls }: { urls: string[] }) {
  return (
    <Grid alignItems="center" spacing={1} sx={{ my: 2 }} container>
      {uniq(urls).map((url) => (
        <TryRenderUnfurl key={url} url={url} />
      ))}
    </Grid>
  );
}
