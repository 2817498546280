import { isServer } from "@converge-collective/common/util";

// ismorphic config - must work on both server and client
//

export const GoogleMapsApiKey = "AIzaSyABQlLAjH25W8oqX3ba3alAJnHV4G8YUho";

export const baseUrl = (): string => process?.env?.BASE_URL || "";

export const devOrProdBaseUrl = (): string => {
  // some urls like /fn/* are only available on dev.converge.is/fn/* or
  // converge.is/fn/* so this can be used to return either of those.
  // for localhost and preview envs it'll use dev.converge.is

  const hostname =
    window !== undefined ? window.location.hostname : "dev.converge.is";
  return hostname === "converge.is"
    ? "https://converge.is"
    : "https://dev.converge.is";
};

// we need to turn off bodyParser in production but keep it on in local dev
// since in production firease functions is already processing the req.body for
// us:
//
// https://firebase.google.com/docs/functions/http-events#read_values_from_the_request
export const apiConfig = isServer && {
  api: {
    bodyParser: process?.env?.BODY_PARSE_ENABLED === "true",
  },
};
