import { VideoFile } from "@mui/icons-material";
import {
  Alert,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MuxPlayer from "@mux/mux-player-react";
import { useQuery } from "@tanstack/react-query";
import { doc } from "firebase/firestore";
import { useState } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { converters } from "~/lib/converter";
import { checkMuxVideoReady } from "~/lib/mux";
import { useLoggedInState } from "~/lib/useLoggedInState";

/**
 * Reusable component to play mux videos by upload ID, checking firestore for
 * the associated playback ID. used by the tiptap editor in edit mode, and also
 * by RenderHTML to replace the custom `mux-video` tag with this component
 */
export function MuxVideo({ uploadId }: { uploadId: string }) {
  const theme = useTheme();
  const { profile, network } = useLoggedInState();
  const firestore = useFirestore();
  const videoUploadDoc = (
    network && uploadId
      ? doc(network.ref, "muxVideoUploads", uploadId)
      : doc(firestore, "noop", "noop")
  ).withConverter(converters.muxVideoUpload.read);
  const { data: muxVideoUpload, status } = useFirestoreDocData(videoUploadDoc);
  const isLoadingMuxVideo = status === "loading";
  const [shouldFetch, setShouldFetch] = useState(true);

  // poll for ready status every 5 seconds until errro or isReady
  useQuery({
    queryKey: ["muxVideoUpload", uploadId],
    queryFn: async () => {
      if (network) {
        const result = await checkMuxVideoReady({ uploadId, network });
        // console.log("checkMuxVideoReady", { result, uploadId });
        // if there's an error stop fetching
        if (!result || "error" in result) {
          console.log("stop fetching", { uploadId });
          setShouldFetch(false);
        }
        // if ready also stop fetching
        if (result && "isReady" in result && result.isReady) {
          console.log("stop fetching", { uploadId });
          setShouldFetch(false);
        }
        return result;
      } else {
        console.warn("no network found");
      }
    },
    refetchInterval: 5000,
    // disable query if we already have a playback id
    enabled: shouldFetch,
  });

  // console.log("MuxVideo", { uploadId, profile, muxVideoUpload });

  if (isLoadingMuxVideo) return null;
  if (!muxVideoUpload) {
    return (
      <Alert className="mux-video-error" severity="error">
        No video found
      </Alert>
    );
  }

  return muxVideoUpload.muxPlaybackInfo ? (
    <MuxPlayer
      accentColor={theme.palette.primary.main}
      playbackId={muxVideoUpload.muxPlaybackInfo.muxPlaybackId}
      metadata={{
        ViewerUserId: profile?.id,
      }}
      autoPlay={false}
      style={{ height: "auto", maxWidth: "100%" }}
    />
  ) : (
    <Stack
      direction="row"
      sx={{ width: "100%", my: 1 }}
      justifyContent="center"
      alignItems="center"
    >
      <Alert sx={{ width: 400 }} severity="info" icon={false}>
        <Stack direction="column" spacing={0} alignItems="center">
          <VideoFile sx={{ my: 1, width: 60, height: 60 }} />
          <Typography variant="h6" fontWeight="bold">
            Optimizing video for insanely fast streaming...
          </Typography>

          <Typography variant="body2" color="textSecondary">
            This should only take a few seconds.
          </Typography>
          <LinearProgress
            value={0}
            valueBuffer={0}
            variant="buffer"
            sx={{ width: "100%", my: 1 }}
          />
        </Stack>
      </Alert>
    </Stack>
  );
}
