import { OpenInFull } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Element, attributesToProps } from "html-react-parser";
import { KeyboardEvent } from "react";
import { useSwipeable } from "react-swipeable";

type ImageProps = {
  node: Element;
  onActiveChange: (mode: "next" | "prev" | "close") => void;
};

export function ImagePreview({ node, onActiveChange }: ImageProps) {
  const attrs = attributesToProps(node.attribs);
  const imgSrc = attrs.src as string;
  // TODO figure out config for firebase resize extension
  // const imgSrc1500 = imageSize(imgSrc, 1500);

  function handleNextPhoto() {
    onActiveChange("next");
  }

  function handlePrevPhoto() {
    onActiveChange("prev");
  }

  // Handling the click away to close the dialog
  const handleClickAway = () => onActiveChange("close");

  // Swipeable handlers
  const handlers = useSwipeable({
    onSwipedLeft: handleNextPhoto,
    onSwipedRight: handlePrevPhoto,
    onTap: handleClickAway,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // Handling keyboard navigation
  const onImageSlide = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowLeft") onActiveChange("prev");
    if (e.key === "ArrowRight") onActiveChange("next");
  };

  return (
    <>
      <Dialog
        open={Boolean(node)}
        onClose={() => onActiveChange("close")}
        fullScreen
        maxWidth={false}
        onKeyDown={onImageSlide}
        sx={{
          boxShadow: "none",
          "& .MuiPaper-root": {
            boxShadow: "none",
            backgroundColor: "transparent",
            alignItems: "center",
          },
        }}
        {...handlers}
      >
        <DialogContent
          {...handlers}
          sx={{
            overflow: "hidden", // To avoid getting scroller since image will have max 100% height
            display: "flex",
            alignItems: "center",
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <img
              alt="Full-size preview"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "100%",
              }}
              src={imgSrc}
            />
          </ClickAwayListener>
        </DialogContent>
      </Dialog>
    </>
  );
}

type CardProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  onImageClick: () => void;
};
const overlay = {
  transition: "all 0.3s ease",
  opacity: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  bottom: 0,
  right: 0,
  top: 0,
  left: 0,
};
const ImgBtn = {
  position: "relative",
  "&:hover": {
    "& .MuiBox-root": {
      opacity: 1,
    },
  },
};
export function ImgCard({ onImageClick, ...props }: CardProps) {
  return (
    <ButtonBase onClick={onImageClick} disableRipple sx={ImgBtn}>
      <img alt="preview" style={{ objectFit: "contain" }} {...props} />
      <Box sx={overlay}>
        <OpenInFull
          color="light"
          style={{ position: "absolute", bottom: 10, right: 10 }}
        />
      </Box>
    </ButtonBase>
  );
}
