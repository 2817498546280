import {
  ActivityLog,
  computePoints,
} from "@converge-collective/common/models/ActivityLog";
import { WithRef } from "@converge-collective/common/models/Base";
import {
  formatNumber,
  shallowTSToDates,
} from "@converge-collective/common/util";
import { startOfDay } from "date-fns";
import { sumBy, groupBy } from "lodash";

export type PointsBreakdown = {
  categoryPoints: number;
  categoryPointsCapped: number;
  weeklyPointsCap?: number;
  goalPoints: number;
  peerRecognitionPoints: number;
  points: number;
  isMaxed: boolean;
};

// computes the daily total taking into account the dailyPointsCap
export const dailyTotal = (
  activities: ActivityLog[],
  dailyPointsCap: undefined | number
): {
  pointsUncapped: number;
  categoryPoints: number;
  goalPoints: number;
  peerRecognitionPoints: number;
  rewardRedemptionPoints: number;
  dailyPoints: number;
} => {
  const init = {
    categoryPoints: 0,
    goalPoints: 0,
    peerRecognitionPoints: 0,
    rewardRedemptionPoints: 0,
  };
  // split the points into category vs goal points.
  // only category points get capped.
  const {
    categoryPoints,
    goalPoints,
    peerRecognitionPoints,
    rewardRedemptionPoints,
  } = activities.map(computePoints).reduce(
    (acc, alWithPoints) => ({
      categoryPoints: acc.categoryPoints + (alWithPoints.categoryPoints || 0),
      goalPoints: acc.goalPoints + (alWithPoints.goalPoints || 0),
      rewardRedemptionPoints:
        acc.rewardRedemptionPoints + (alWithPoints.rewardRedemptionPoints || 0),
      peerRecognitionPoints:
        acc.peerRecognitionPoints + (alWithPoints.peerRecognitionPoints || 0),
    }),
    init
  );

  const pointsUncapped = categoryPoints + goalPoints + peerRecognitionPoints;
  const categoryPointsCapped = Math.min(
    categoryPoints,
    dailyPointsCap || Infinity
  );
  const dailyPoints =
    categoryPointsCapped +
    goalPoints +
    peerRecognitionPoints +
    rewardRedemptionPoints;

  return {
    pointsUncapped,
    categoryPoints: categoryPointsCapped,
    goalPoints,
    peerRecognitionPoints,
    rewardRedemptionPoints,
    dailyPoints,
  };
};

export type ActivitySummary = {
  dailyPointsCap?: number;
  percentEarned?: number;
  date: Date;
  dailyPoints: number;
  categoryPoints: number;
  goalPoints: number;
  peerRecognitionPoints: number;
  pointsDesc: JSX.Element;
  isMaxed: boolean;
  activities: WithRef<ActivityLog>[];
};

export const dailyActivitySummary = (
  activities: WithRef<ActivityLog>[],
  dailyPointsCap?: number
): ActivitySummary[] => {
  const activitiesByDate = groupBy(activities.map(shallowTSToDates), (a) =>
    startOfDay(a.date)
  );

  return Object.entries(activitiesByDate).map(([date, activities]) => {
    const {
      dailyPoints,
      categoryPoints,
      goalPoints,
      peerRecognitionPoints,
      rewardRedemptionPoints,
    } = dailyTotal(activities, dailyPointsCap);

    const isMaxed = categoryPoints === dailyPointsCap;

    const pointsDesc = (
      <>
        Activity points: {categoryPoints}
        <br />
        Goal points: {goalPoints}
        <br />
        {peerRecognitionPoints > 0 && (
          <>
            Peer recognition points: {peerRecognitionPoints}
            <br />
          </>
        )}
        {rewardRedemptionPoints !== 0 && (
          <>Reward redemption points: {rewardRedemptionPoints}</>
        )}
      </>
    );

    const percentEarned =
      dailyPointsCap && (categoryPoints / dailyPointsCap) * 100;
    return {
      dailyPointsCap,
      date: new Date(date),
      dailyPoints,
      categoryPoints,
      goalPoints,
      peerRecognitionPoints,
      rewardRedemptionPoints,
      pointsDesc,
      percentEarned,
      isMaxed,
      activities,
    };
  });
};

export const weeklyPointsFromSummary = (
  activitiesWithSummary: ActivitySummary[]
): number => sumBy(activitiesWithSummary, (a) => a.dailyPoints);

export const computePointsBreakdownDescription = ({
  categoryPointsCapped,
  categoryPoints,
  goalPoints,
  peerRecognitionPoints,
  points,
  isMaxed,
}: PointsBreakdown): React.ReactElement => (
  <>
    {formatNumber(categoryPointsCapped)} points from activities +{" "}
    {peerRecognitionPoints > 0 && (
      <>{formatNumber(peerRecognitionPoints)} points from peer recognition + </>
    )}
    {formatNumber(goalPoints)} points from goal checkboxes ={" "}
    {formatNumber(points)} total points!
    {isMaxed && (
      <>
        <br />
        <br />
        🌟 You hit the weekly max! Uncaped points:{" "}
        {formatNumber(categoryPoints)}
      </>
    )}
  </>
);

// const cappedActivityPoints = weeklyPointsCap
//   ? Math.min(categoryPoints, weeklyPointsCap)
//   : categoryPoints;
// const isMaxed = weeklyPointsCap && cappedActivityPoints === weeklyPointsCap;
// const userTotalPoints = cappedActivityPoints + goalPoints;
