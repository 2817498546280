import { LiteProfile } from "@converge-collective/common/models/Profile";
import { Chip, Stack } from "@mui/material";
import { collectionGroup, query, where } from "firebase/firestore";
import Link from "next/link";
import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import useProfile from "~/hooks/useProfile";
import { converters } from "~/lib/converter";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { groupDetailRoute } from "~/routes";

export default function ProfileGroups({
  liteProfile,
}: {
  liteProfile: LiteProfile;
}): React.ReactElement {
  const firestore = useFirestore();
  const { network } = useLoggedInState();
  // fetch the full profile to get the groups
  const [profile] = useProfile(liteProfile.id);
  // NOTE: we can't use `currentNetworkMembership` because the target profile
  // could be on a different network, so look up their memberships directly
  // instead.
  const baseQuery = query(
    collectionGroup(firestore, "groupMembers").withConverter(
      converters.groupMember.read
    ),
    where("profile.id", "==", liteProfile.id)
  );

  const { data: groupMembers } = useFirestoreCollectionData(
    network
      ? query(baseQuery, where("network.id", "==", network.id))
      : baseQuery
  );

  return (
    (profile && groupMembers && (
      <Stack
        sx={{ flexWrap: "wrap", gap: 1, my: 1 }}
        justifyContent="center"
        direction="row"
        spacing={1}
      >
        {network &&
          groupMembers.map((gm) => (
            <Link
              key={gm.group.id + gm.id}
              href={groupDetailRoute(network.slug, gm.group.slug)}
              legacyBehavior
            >
              <Chip
                sx={{ m: 0.1 }}
                size="small"
                color="secondary"
                label={gm.group.name}
              />
            </Link>
          ))}
      </Stack>
    )) || <div />
  );
}
