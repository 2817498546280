import { lighten, useTheme } from "@mui/material";

/** Shared styles between RenderHTML and the MuiTipTap editor */
export function useRenderHTMLStyle() {
  const theme = useTheme();
  return {
    // this gets overriden by built in ProseMirror styles targetting:
    // .ProseMirror [data-type="mention"]
    // so target that as well in over to override without using !important
    "& .mention, .ProseMirror [data-type='mention']": {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.9)}`,
      padding: "1px 2px",
      borderRadius: "4px",
      cursor: "pointer",
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  };
}
