import { imageSize } from "@converge-collective/common/util";
import { Masonry } from "@mui/lab";
import { Box, SxProps, useMediaQuery, useTheme } from "@mui/material";
import type { CSSProperties } from "@mui/styles";
import type { DOMNode } from "html-react-parser";
import htmlParse, {
  Element,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { isEmpty } from "lodash";
import Link from "next/link";
import { useEffect, useMemo, useRef, useState } from "react";
import { baseUrl } from "~/lib/config";
import { ImagePreview, ImgCard } from "./ImagePreview";
import { RenderUnfurls } from "./RenderUnfurls";
import { useRenderHTMLStyle } from "./useRenderHTMLStyle";
import { MuxVideo } from "./video/MuxVideo";

/**
 * - Turn normal anchor tags into Next.js Link elements for client-side routing
 *   Wrap img tags in a <div> for alignment
 * - Find URLs to unfurl and place below the rendered HTML
 * - Replace `mux-video` tags with a video player
 * - Replace `img` tags with a custom image component and use gallery if there
 *   are multiple images
 *
 * We transform HTML ourselves rather than use TipTap in view mode. We may want
 * to revisit this decision but if we did we'd need to know that HTML is
 * renderable via tip tap (rather than just abritrary HTML). Maybe use the type
 * system?
 */
const parseAndEnhanceHtml = (
  html: string,
  videoRef: React.RefObject<HTMLVideoElement | null>,
  videoTimer: number,
  setVideoTimer: (timer: number) => void,
  enableImageGrid?: boolean,
  setActiveImage?: (idx: number) => void
) => {
  // first count how many img elements there are and if there are < 2 disable
  // the image grid
  let imageCount = 0;
  htmlParse(html, {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.name === "img") {
        imageCount++;
      }
    },
  });

  const urls: string[] = [];
  const mediaNodes: Element[] = []; // Collect image sources for masonry layout

  const parsedHtml = htmlParse(html, {
    replace: (domNode) => {
      const isElement = domNode instanceof Element;
      if (isElement && domNode.attribs) {
        // Media Nodes are extracted out as they will be rendered in masonry
        const isMediaNode = domNode.name === "img" || domNode.name === "video";
        if (imageCount > 1 && enableImageGrid && isMediaNode) {
          mediaNodes.push(domNode);
          return <></>;
        }

        // Mux video
        if (domNode.name === "mux-video") {
          // console.log("MuxVideo", domNode.attribs);
          const uploadId = domNode.attribs.uploadid;
          return (
            <Box>
              <MuxVideo uploadId={uploadId} />
            </Box>
          );
        }

        // Image render
        if (domNode.name === "img") {
          const nodeId = mediaNodes.length;
          mediaNodes.push(domNode);
          return RenderImg(domNode, () => setActiveImage?.(nodeId));
        }

        // Link render
        if (domNode.name === "a") {
          urls.push(domNode.attribs.href);
          return RenderLink(domNode);
        }

        // Video Render
        if (domNode.name === "video")
          return RenderVideo(domNode, videoRef, videoTimer, setVideoTimer);
      }
    },
  });

  return { parsedHtml, urls, mediaNodes };
};

function RenderVideo(
  domNode: Element,
  videoRef: React.RefObject<HTMLVideoElement | null>,
  videoTimer: number,
  setVideoTimer: (timer: number) => void
) {
  const videoProps = attributesToProps(domNode.attribs);
  const alignment = domNode.attribs["data-align"];

  const divStyle = {
    width: "100%",
    // disabled this because it was rendering way too wide on mobile
    // width: "max-content",
    ...(alignment &&
      getContentAlignement(alignment as CSSProperties["textAlign"])),
    ...(videoProps.width && { maxWidth: `${videoProps.width}px` }),
  };
  const sourceNode = domNode.children.find(
    (childNode) => (childNode as Element).name === "source"
  ) as Element | undefined;

  const videoSrc = sourceNode?.attribs.src;
  const posterUrl = videoSrc ? `${videoSrc}.jpg` : "";

  const handleMouseEnter = () => {
    if (videoRef?.current) {
      if (videoRef.current.paused) {
        videoRef.current.currentTime = videoTimer;
        videoRef.current.play();
      }

      setTimeout(() => {
        videoRef.current?.pause();

        setVideoTimer(videoTimer + 5);
      }, 5000); // Play for 5 seconds
    }
  };

  return (
    <div style={divStyle}>
      <video
        {...videoProps}
        width={"100%"}
        preload="metadata"
        poster={posterUrl}
        ref={videoRef}
        onMouseEnter={handleMouseEnter}
        muted
      >
        {domNode.children.map((childNode, index) => {
          if ((childNode as Element).name === "source") {
            const sourceProps = attributesToProps(
              (childNode as Element).attribs
            );
            return <source key={index} {...sourceProps} />;
          }
          return null;
        })}
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

function RenderImg(
  domNode: Element,
  setActiveImage?: () => void,
  onlyImg?: boolean
) {
  const props = attributesToProps(domNode.attribs);
  const style = props.style;
  const width = props.width;
  const imgSrc = props.src as string;
  const imgSrc600 = imageSize(imgSrc, 600);
  const divStyle = {
    width: "auto", // This will handle images width automatically without changing aspect ratio
    // Text alignment property doesn't aligns image at give alignment
    ...(style?.textAlign &&
      getContentAlignement(style.textAlign as CSSProperties["textAlign"])),
    ...(width && { maxWidth: `${width}px` }),
  };
  const imgPrevJSX = (
    <ImgCard
      src={onlyImg ? imgSrc600 : imgSrc}
      onImageClick={() => setActiveImage && setActiveImage()}
    />
  );
  if (onlyImg) return imgPrevJSX;
  return <div style={divStyle}>{imgPrevJSX}</div>;
}

function getContentAlignement(align: CSSProperties["textAlign"]) {
  if (align === "start" || align === "left") {
    return {};
  }
  if (align === "end" || align === "right") {
    return { marginLeft: "auto" };
  }
  return { margin: "0 auto" };
}

function RenderLink(domNode: Element) {
  const props = attributesToProps(domNode.attribs);
  const href = domNode.attribs.href;
  const isInternal = href.startsWith(baseUrl()) || href.startsWith("/");
  const extraProps = isInternal ? {} : { target: "_blank" };
  return (
    <Link legacyBehavior href={href} {...props}>
      <a {...props} {...extraProps}>
        {domToReact(domNode.children as DOMNode[])}
      </a>
    </Link>
  );
}
/**
 * We allow users to upload images in the rich text editor without resizing
 * them, so any time we render HTML (output from the editor) we need to constrain
 * max width on images. Having a common RenderHTML component also allows us to
 * switch out html parsing centrally if we ever want to move away from
 * html-react-parser.
 */

export function RenderHTML({
  html,
  sx,
  enableUnfurls = false,
  enableImageGrid = false,
}: {
  html: string;
  sx?: SxProps;
  enableUnfurls?: boolean;
  enableImageGrid?: boolean;
}): React.ReactElement {
  const renderHtmlStyles = useRenderHTMLStyle();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoTimer, setVideoTimer] = useState(0);
  const [activeImage, setActiveImage] = useState<undefined | number>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down("sm") ?? false);

  // count how many media nodes there are. if there's more than 1 use a masonry
  // grid.
  const { parsedHtml, urls, mediaNodes } = useMemo(
    () =>
      parseAndEnhanceHtml(
        html,
        videoRef,
        videoTimer,
        setVideoTimer,
        enableImageGrid,
        (idx: number) => setActiveImage(idx)
      ),
    [enableImageGrid, html, videoTimer]
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const updateTime = () => {
      if (!videoElement.paused) {
        setVideoTimer(videoElement.currentTime);
      }
    };

    videoElement.addEventListener("timeupdate", updateTime);
    return () => {
      videoElement.removeEventListener("timeupdate", updateTime);
    };
  }, []);

  const activeNode =
    typeof activeImage !== "undefined" ? mediaNodes[activeImage] : null;
  const onActiveNodeChange = (mode: "next" | "prev" | "close") => {
    if (mode === "next") {
      const nextNode = activeImage ? activeImage + 1 : 1;
      const hasNextNode = mediaNodes[nextNode];
      hasNextNode && setActiveImage(nextNode);
    }
    if (mode === "prev") {
      const prevNode = activeImage ? activeImage - 1 : 0;
      const hasPrevNode = mediaNodes[prevNode];
      hasPrevNode && setActiveImage(prevNode);
    }
    if (mode === "close") {
      setActiveImage(undefined);
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        ...renderHtmlStyles,
        wordBreak: "break-word",
        "& img": { maxWidth: "100%" },
        "& table": {
          border: "1px solid #ccc",
          borderCollapse: "collapse",
        },
        "& th": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          border: "1px solid #ccc",
          borderCollapse: "collapse",
        },
        "& td": {
          border: "1px solid #ccc",
          borderCollapse: "collapse",
        },
        "& *": { wordBreak: "break-word", maxWidth: "inherit" },
        "& ul[data-type='taskList']": {
          paddingInlineStart: 0,
          padding: 0,
          margin: 0,
        },
        "& ul, ol": { margin: 0 },
        "& ol": {
          listStyleType: "decimal",
          "& ol": {
            listStyleType: "lower-alpha",
            "& ol": {
              listStyleType: "lower-roman",
              "& ol": {
                listStyleType: "decimal",
                "& ol": {
                  listStyleType: "lower-alpha",
                  "& ol": {
                    listStyleType: "lower-roman",
                  },
                },
              },
            },
          },
        },

        // code blocks
        "& code": {
          color: "rgb(160, 121, 4)",
          backgroundColor: "rgb(0, 0, 0, 0.04)",
          borderRadius: "3px",
          border: "1px solid rgb(0, 0, 0, 0.12)",
          padding: "2px 3px 1px",
        },

        // header and paragraph styles
        "& h1, h2, h3, h4, h5, h6": {
          marginBlockStart: "0",
          marginBlockEnd: "0",
        },
        "& p": {
          marginBlockStart: "0",
          marginBlockEnd: "0",
          padding: "3px",
          minHeight: "16px",
        },
        "&": isMobile ? { maxWidth: "100%", overflow: "hidden" } : {},
      }}
    >
      {parsedHtml}

      {enableImageGrid && mediaNodes.length > 1 && (
        <Masonry columns={isMobile ? 2 : 3} spacing={1} sx={{ mt: 2 }}>
          {mediaNodes.map((node, idx) => {
            if (node.name === "video") {
              return RenderVideo(node, videoRef, videoTimer, setVideoTimer);
            } else {
              return RenderImg(node, () => setActiveImage(idx), true);
            }
          })}
        </Masonry>
      )}

      {activeNode && (
        <ImagePreview node={activeNode} onActiveChange={onActiveNodeChange} />
      )}
      {enableUnfurls && !isEmpty(urls) && <RenderUnfurls urls={urls} />}
    </Box>
  );
}
