"use client";

import { LiteNetwork } from "@converge-collective/common/models/Network";
import { Upload } from "@mux/mux-node/resources/video/uploads";
import { devOrProdBaseUrl } from "./config";
import { Asset } from "@mux/mux-node/resources/video/assets";

type ErrorResponse = { error: unknown };

const allowedOrigins = [
  "http://localhost:3000",
  "https://dev.converge.is",
  "https://converge.is",
];

const dynamicCorsOrigin = (origin: string) => {
  // Allow exact matches
  if (allowedOrigins.includes(origin)) {
    return origin;
  }

  // Allow wildcard pattern for *.web.app
  const webAppPattern = /^https:\/\/[a-z0-9-]+\.web\.app$/;
  if (webAppPattern.test(origin)) {
    return origin;
  }

  // Default to rejecting the origin
  return null;
};

export const checkMuxVideoReady = async ({
  uploadId,
  network,
}: {
  uploadId: string;
  network: LiteNetwork;
}) => {
  const url = new URL("/fn/check-mux-video-ready", devOrProdBaseUrl());
  const payload = {
    networkPath: network.ref.path,
    uploadId,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const data:
      | { isReady: boolean | undefined; upload: Upload; asset: Asset }
      | ErrorResponse = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking Mux video:", error);
  }
};

export const createMuxUploadUrl = async ({
  network,
}: {
  network: LiteNetwork;
}) => {
  const url = new URL("/fn/create-mux", devOrProdBaseUrl());
  const corsOrigin = dynamicCorsOrigin(window.location.origin);
  const payload = {
    networkSlug: network.slug,
    networkPath: network.ref.path,
    corsOrigin,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: Upload | ErrorResponse = await response.json();
    console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error creating Mux:", error);
  }
};
