import {
  ActivityLogKinds,
  CategoryActivityLog,
  computePoints,
  computePointsDescription,
} from "@converge-collective/common/models/ActivityLog";
import {
  formatDateTime,
  formatNumber,
  formatShortDate,
  formatTime,
} from "@converge-collective/common/util";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { createStyles, makeStyles, styled } from "@mui/styles";
import { addWeeks, format, startOfWeek } from "date-fns";
import { collection, orderBy, query, where } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import useProfile from "~/hooks/useProfile";
import { converters } from "~/lib/converter";
import {
  dailyActivitySummary,
  weeklyPointsFromSummary,
} from "~/lib/fmt/leaderboards";
import CircularProgressWithLabel from "../CircularWithLabel";
import { RenderHTML } from "../RenderHTML";
import { ChatButton } from "./ChatButton";
import ProfileGroups from "./ProfileGroups";
import { ProfilePreviewProps } from "./ProfilePreview";
import UserAvatar from "./UserAvatar";
import { useLoggedInState } from "~/lib/useLoggedInState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      pointerEvents: "auto",
    },
    nameType: {},
    highlight: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    rowIcon: { marginRight: theme.spacing(0.5) },
  })
);

// TODO - the network should store the timezone of the challenge and all
// `startOfWeek` calls should use that timezone so that the leaderboard doesn't
// show different points than when we fetch the activity logs directly.
export function ExpandedProfile({
  team,
  profile: liteProfile,
  setIsDrawerOpen,
  sx,
}: ProfilePreviewProps & {
  // when a parent renders ExpandedProfile in a drawer or modal, allow the
  // parent to pass a function to close the modal, such as when opening up a
  // direct message chat.
  setIsDrawerOpen?: (open: boolean) => void;
}): React.ReactElement {
  const classes = useStyles();
  const [now] = useState(new Date());
  const firestore = useFirestore();
  const { network } = useLoggedInState();

  // fetch the full profile to ensure we have up-to-date photo and info
  const [profile] = useProfile(liteProfile.id);

  const { data: userActivities } = useFirestoreCollectionData(
    query(
      collection(
        firestore,
        "profiles",
        liteProfile.id,
        "activityLogs"
      ).withConverter(converters.activityLog.read),
      orderBy("date", "desc"),
      where("date", ">=", startOfWeek(now))
    )
  );

  const router = useRouter();
  // when the route changes, close the profile preview if it's in a drawer or
  // modal
  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setIsDrawerOpen?.(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // disable vertical scroll on the table - allow the entire parent to scroll
  // instead
  const tableProps = { overflowX: "unset", mb: 1 };

  const { dailyPointsCap } = network?.settings || {};

  const activitiesWithSummary = dailyActivitySummary(
    userActivities || [],
    dailyPointsCap
  );
  const weeklyPoints = weeklyPointsFromSummary(activitiesWithSummary);

  const cellStyle: SxProps<Theme> = {
    large: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  };

  const HeaderRow = styled(TableRow)(({ theme }) => ({
    "& td": {
      backgroundColor: theme.palette.background.default,
      fontWeight: "bold",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  if (!profile) {
    console.warn("ProfileExpanded waiting for profile", { profile });
    return (
      <Stack direction="row" justifyContent="center" sx={{ my: 4 }}>
        <CircularProgress size={100} />
      </Stack>
    );
  }

  return (
    <>
      <Grid container sx={{ ...sx, my: 1 }}>
        <Grid item sm={12} sx={{ flexGrow: 1 }}>
          <Stack sx={{ m: 1 }} direction="row" justifyContent="center">
            <UserAvatar
              sx={{
                m: 1,
                width: { md: 300, xs: 150 },
                height: { md: 300, xs: 150 },
              }}
              useFullSize={true}
              profile={profile}
            />
          </Stack>
        </Grid>
        <Grid sx={{ mx: 1 }} item sm={12}>
          <Stack direction="column" alignItems="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={1}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {profile.name}
              </Typography>
              {team && (
                <Chip
                  sx={{ mt: 2, ml: 1, height: 20 }}
                  icon={<Diversity3Icon />}
                  label={team.name}
                  size="small"
                  color="primary"
                />
              )}
            </Stack>

            {profile.city && (
              <Typography variant="body1">
                {`${profile.city}${
                  (profile.state && ", " + profile.state) || ""
                }`}
              </Typography>
            )}

            {profile.lastSeenAt ? (
              <Typography>
                <b>Last seen at:</b> {formatDateTime(profile.lastSeenAt)}
              </Typography>
            ) : null}

            {profile.title && (
              <Typography variant="body1">
                <b>Title:</b> {profile.title}
              </Typography>
            )}

            {profile.bio && (
              <Typography sx={{ maxWidth: "md" }} variant="body1">
                <b>Bio:</b> {profile.bio}
              </Typography>
            )}
          </Stack>

          {userActivities?.length > 0 && (
            <>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <EmojiEventsIcon
                    className={classes.rowIcon}
                    color="secondary"
                  />
                </Box>
                <Typography variant="body1">
                  <span className={classes.highlight}>
                    {formatNumber(weeklyPoints)} points
                  </span>{" "}
                  from{" "}
                  <span className={classes.highlight}>
                    {formatNumber(userActivities.length)}{" "}
                    {formatNumber(userActivities.length) === "1"
                      ? "activity"
                      : "activities"}
                  </span>{" "}
                  this week,{" "}
                  <span>
                    {formatShortDate(startOfWeek(now))}
                    {" - "}
                    {formatShortDate(addWeeks(startOfWeek(now), 1))}
                  </span>
                </Typography>
              </Box>
              {dailyPointsCap && (
                <Typography variant="body1" textAlign="center">
                  Points from activities are capped at{" "}
                  <span className={classes.highlight}>
                    {formatNumber(dailyPointsCap)}
                  </span>{" "}
                  per day.
                </Typography>
              )}
            </>
          )}
          <Stack direction="row" justifyContent="center" sx={{ my: 1 }}>
            {network?.settings?.isDirectMessagingDisabled !== true && (
              <ChatButton
                onOpenChat={() => setIsDrawerOpen?.(false)}
                profile={profile}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <ProfileGroups liteProfile={profile} />

      <TableContainer sx={tableProps}>
        <Table size="small" stickyHeader>
          <TableBody>
            {activitiesWithSummary.map(
              ({
                date,
                percentEarned,
                pointsDesc,
                dailyPoints,
                // categoryPoints,
                // goalPoints,
                isMaxed,
                activities,
              }) => (
                <React.Fragment key={date.toString()}>
                  <HeaderRow key={date.toString()}>
                    <TableCell colSpan={1}>
                      {format(date, "EEEE L'/'d")}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} colSpan={3}>
                      <Stack
                        justifyContent="flex-end"
                        alignItems="center"
                        direction="row"
                      >
                        <Typography sx={{ mr: 1 }}>
                          <Tooltip
                            arrow
                            title={pointsDesc}
                            sx={{
                              "& .MuiTooltip-tooltip": { textAlign: "center" },
                            }}
                          >
                            <span>{formatNumber(dailyPoints)} points</span>
                          </Tooltip>
                        </Typography>
                        {percentEarned !== undefined && percentEarned > 0 && (
                          <CircularProgressWithLabel
                            size={37}
                            thickness={4}
                            color={
                              percentEarned < 10
                                ? "error"
                                : isMaxed
                                  ? "primary"
                                  : percentEarned > 50
                                    ? "success"
                                    : "secondary"
                            }
                            value={percentEarned}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </HeaderRow>
                  {activities.map((activityLog) => (
                    <TableRow key={activityLog.id}>
                      <TableCell colSpan={2}>
                        <RenderHTML html={activityLog.description} />
                        {activityLog.kind ===
                          ActivityLogKinds.CategoryActivityLog &&
                          activityLog.description !==
                            activityLog.categoryName && (
                            <>
                              {`${
                                !isEmpty(activityLog.description) ? " – " : ""
                              }
                                ${activityLog.categoryName}`}
                            </>
                          )}
                        {activityLog.kind ===
                          ActivityLogKinds.CategoryActivityLog && (
                          <>
                            {" – " +
                              (activityLog as CategoryActivityLog).duration +
                              " minutes"}
                          </>
                        )}
                      </TableCell>
                      <TableCell colSpan={1} sx={cellStyle}>
                        {formatTime(activityLog.date)}
                      </TableCell>
                      <TableCell colSpan={1} sx={cellStyle}>
                        <Tooltip
                          arrow
                          title={computePointsDescription(activityLog)}
                          sx={{
                            "& .MuiTooltip-tooltip": { textAlign: "center" },
                          }}
                        >
                          <span style={{ textAlign: "center" }}>
                            {formatNumber(computePoints(activityLog).points)}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
