import { WithID } from "@converge-collective/common/models/Base";
import { Chat } from "@converge-collective/common/models/Chat";
import {
  nanoProfile,
  NanoProfile,
} from "@converge-collective/common/models/Profile";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useRouter } from "next/router";
import objectHash from "object-hash";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { useFirestore } from "reactfire";
import { converters } from "~/lib/converter";
import { chatRoute } from "~/routes";
import { useLoggedInState } from "./useLoggedInState";

/**
 * State flows for chat
 */
const initState: {
  // store enough data on chatToOpen that it can be created if it doesn't
  // already exist
  chatToOpen?: Pick<WithID<Chat>, "id" | "from" | "members">;
  // the selected chat (assumes it already exists in firestore)
  selectedChatId?: string;
} = {
  chatToOpen: undefined,
  selectedChatId: undefined,
};

export const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState(initState);

/**
 * Main hook that initiates and handles the chat state
 */
export const useChatState = (): void => {
  // and also ensure chats exist when being opened
  const firestore = useFirestore();
  const { network } = useLoggedInState();
  const router = useRouter();
  const [chatToOpen] = useGlobalState("chatToOpen");

  useEffect(
    function ensureChatToOpen() {
      console.log("chat useEffect chatToOpen", { chatToOpen });
      const ensureChat = async () => {
        if (chatToOpen) {
          const chatRef = doc(firestore, `chats/${chatToOpen.id}`);
          console.log("ensure chat", { chatToOpen, path: chatRef.path });
          const chat = await getDoc(chatRef);
          console.log("ensure chat", {
            network,
            chatToOpen,
            path: chatRef.path,
            exists: chat.exists(),
          });
          if (network && !chat.exists()) {
            const { from, members } = chatToOpen;
            // ensure the `from` profile is in the members list too. this is
            // essential for firestore rules to work
            const membersWithFrom = members.find((m) => m.id === from.id)
              ? members
              : [nanoProfile(from), ...members];
            try {
              const newChat: Chat = {
                activeNetwork: network,
                from,
                members,
                lastActivityAt: new Date(),
                memberUids: membersWithFrom.map((m) => m.id),
                recentMessages: [],
              };
              console.log("creating chat", { newChat });
              await setDoc(
                chatRef.withConverter(converters.chat.write),
                newChat
              );
            } catch (e) {
              console.error("error creating chat", { e });
            }
          }
          // after ensuring the chat exists set selectedChatId so the UI can
          // respond and reset chatToOpen
          // setGlobalState("selectedChatId", chatToOpen.id);
          console.log("ensureChat", { chatToOpen });
          setGlobalState("chatToOpen", undefined);
          router.push(chatRoute(network?.slug || "", chatToOpen.id));
        }
      };

      // if a selected chat gets set, ensure the group chat exists
      ensureChat();
    },
    [router, network, firestore, chatToOpen]
  );
};

// first ensures the chat exists and then sets global state to open it
export const openGroupChat = (
  from: NanoProfile,
  members: NanoProfile[]
): void => {
  // ensure `from` is in the full set of members
  const allMembers = members.find((m) => m.id === from.id)
    ? members
    : [from, ...members];

  const id = groupId(allMembers.map((m) => m.id));
  // setGlobalState("chatOpen", true);
  setGlobalState("chatToOpen", { id, from, members: allMembers });
  console.log("openGroupChat", { id, from, members: allMembers });
};

/** Returns a unique id for a group chat based on a hash of the sorted member uuids */
export const groupId = (uuids: string[]): string => {
  const sorted = uuids.sort();
  return objectHash(sorted.join(""));
};

/* This function is used for toggle overflow on the body. when the sceensize is less than or equal to 600px and chat modal opened. */
export const toggleOverflowFromBody = (isChatPanelOpend: boolean) => {
  const screnSize = screen.width;
  if (screnSize <= 600 && isChatPanelOpend)
    document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";
};
