import {
  LiteProfile,
  NanoProfile,
} from "@converge-collective/common/models/Profile";
import { imageSize } from "@converge-collective/common/util";
import { Avatar, SxProps, Tooltip } from "@mui/material";

export default function UserAvatar({
  profile,
  sx,
  className,
  useFullSize = false,
}: {
  profile: LiteProfile | NanoProfile;
  sx?: SxProps;
  className?: string;
  useFullSize?: boolean;
}): React.ReactElement {
  return (
    <Tooltip key={profile.id} title={profile.name}>
      <Avatar
        sx={sx}
        className={className}
        key={profile.name}
        alt={profile.name}
        src={useFullSize ? profile.photoURL : imageSize(profile.photoURL, 600)}
      />
    </Tooltip>
  );
}
