import {
  LiteProfile,
  liteProfile,
} from "@converge-collective/common/models/Profile";
import { permissions } from "@converge-collective/common/models/rbac/NetworkPermission";
import MessageIcon from "@mui/icons-material/Message";
import { Button } from "@mui/material";
import React from "react";
import { useUser } from "reactfire";
import { usePermission } from "~/hooks/usePermission";
import useProfile from "~/hooks/useProfile";
import { openGroupChat } from "~/lib/chat";

export function ChatButton({
  // the target profile to chat with
  profile,
  onOpenChat,
}: {
  profile: LiteProfile;
  onOpenChat?: () => void;
}): React.ReactNode {
  const { data: user } = useUser();
  const [userProfile] = useProfile(user?.uid);

  const { canPerform: canDirectMessage } = usePermission(
    permissions.directMesssagesCreate
  );
  if (!canDirectMessage) return <></>;

  if (user?.uid === profile.id) {
    // can't chat with self
    return <></>;
  }

  const liteUserProfile = userProfile && liteProfile(userProfile);

  return (
    <>
      {user && userProfile && liteUserProfile && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            openGroupChat(liteUserProfile, [profile, liteUserProfile]);
            onOpenChat?.();
          }}
          startIcon={<MessageIcon />}
          size="small"
          color="primary"
        >
          Message {profile.name}
        </Button>
      )}
    </>
  );
}
