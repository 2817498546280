import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";

export default function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    label?: string;
    tooltip?: string;
  }
): React.ReactElement {
  const labelText = props.label ? props.label : `${Math.round(props.value)}%`;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{ color: "whitesmoke" }}
        value={100}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          sx={{
            animationDuration: "550ms",
          }}
          variant="determinate"
          {...props}
        />
      </Box>

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: 8, cursor: "default" }}
        >
          {props.tooltip ? (
            <Tooltip
              arrow
              title={props.tooltip}
              sx={{
                "& .MuiTooltip-tooltip": { textAlign: "center" },
              }}
            >
              <span>{labelText}</span>
            </Tooltip>
          ) : (
            labelText
          )}
        </Typography>
      </Box>
    </Box>
  );
}
