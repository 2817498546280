import { Unfurl, unfurlId } from "@converge-collective/common/models/Unfurl";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { converters } from "~/lib/converter";

const urlOrNull = (url: string) => {
  try {
    const u = new URL(url);
    return u;
  } catch {
    return null;
  }
};

/** Attempt to fetch an unfurl from the database.
 * If it doesn't exist yet, create it and wait for the background
 * function to fetch the result.
 */
export function useUnfurl(url: string) {
  const firestore = useFirestore();
  const validatedUrl = urlOrNull(url);
  const unfId = validatedUrl && unfurlId(url);
  const unfDoc = (
    unfId
      ? doc(collection(firestore, "unfurls"), unfId)
      : doc(firestore, "noop/noop")
  ).withConverter(converters.unfurl.read);
  const { data: unfurl, status } = useFirestoreDocData(unfDoc);
  const isLoading = status === "loading";

  // in the background, check if the doc exists and write it if not
  useEffect(
    function lazyCreateUnfurl() {
      const lazyCreate = async () => {
        if (!unfId) return;
        const docSnap = await getDoc(unfDoc);
        if (!docSnap.exists()) {
          try {
            const u: Unfurl = {
              url,
              id: unfId,
              createdAt: new Date(),
            };
            await setDoc(unfDoc, u);
          } catch (e) {
            console.error("error creating unfurl", { e, unfDoc });
          }
        }
      };
      unfId && lazyCreate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unfId]
  );

  return { unfurl, isLoading };
}
